jQuery(function($){

    if ($('body.template-tours').length > 0)
    {
        /**************************
     * Listar Tours
     *****************/
    var page = 1;
    var slug = $('.list-group-item.active').data('slug');
    var termid = $('.list-group-item-tours.active').data('termid');
    var listarToursAjax = function (page, termid, slug) {
        //alert ('foi');
        $.ajax({
            url:ajaxwp.ajaxurl,
            type:'GET',
            data:{
                action: 'listarTours',
                page: page,
                termid:termid,
                slug: slug
            },
            beforeSend:function () {
               console.log('carregando Tours');
                $('.loader').addClass('loading');
                $('.progress').removeClass('d-none');
                $("main").toggleClass('block-scroll'); 
                //$('.bottom-floaters-container').toggleClass('opacity-none');
            }
        })
        .done(function(response){
            console.log('Tours carregados');
            //$("main").toggleClass('block-scroll'); 
           // $('.loader').removeClass('loading');
            //$('.progress').addClass('d-none');
         
            $('#listar-tours').html(response);
            //$('.bottom-floaters-container').toggleClass('opacity-none');
        })
        .fail(function(){
            console.log('opss erro a carregar Tours!!!');
        });
    };
    listarToursAjax(page, termid, slug);

    //Ação do botão da categoria
    $('.list-group-item-tours').on('click', function () {
       // alert ("teste");
       slug = $(this).data('slug');
       termid = $(this).data('termid');
       listarToursAjax(1, termid, slug);
       $('.list-group-item-tours').removeClass('active');
       $(this).addClass('active');
    });


    //Ação do botão da paginação
    $('body').on('click', '.page-item', function () {
        // alert ("teste");
        page = $(this).find('span').text();
       listarToursAjax(page, termid, slug);
        $('.page-item').removeClass('active');
        $(this).addClass('active');
 
    });


   
    /******************************************************************************** */

    }

    

    

});